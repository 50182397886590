import axios from "axios";
import { BackendAPI } from "../constants/backendAPI"
import Cookies from "universal-cookie";
import { handleException } from "../utils/dataFetchHelper";
import HttpResponse from "../model/HttpResponseModal";

const cookies = new Cookies()

/**
 * @typedef {object} MessageModel
 * @property {number} id
 * @property {string} header
 * @property {string} message
 * @property {boolean} read
 * @property {string} received_by
 * @property {number} receiver
 * @property {boolean} receiver_delete
 * @property {string | null} receiver_name
 * @property {number} sender
 * @property {boolean} sender_delete
 * @property {string } sender_name
 * @property {string} sent_by
 * @property {string} created_at
 */



/**
 * @typedef {object} AdminEmailModel
 * @property {number} id
 * @property {string} name
 * @property {number} user_id
 * @property {string} email
 */


export async function getMessages(messageType = "All", currentPage = 1, startDate = "", endDate = "") {
    const result = [{ count: 0, result: [] }, null];
    try {
        const res = await axios.get(BackendAPI.message.getMessage, {
            params: {
                message_type: messageType,
                page: currentPage,
                start_date: startDate,
                end_date: endDate
            }, headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        })
        result[0].count = res.data.count;
        result[0].result = res.data.results;
        return result
    } catch (err) {
        result[1] = handleException(err);
        return result
    }
}





/**
 * @returns {Promise<[AdminEmailModel[]|null, HttpResponse | null]>}
 */
export async function getAdminEmails() {
    const result = [null, null];

    try {
        const res = await axios.get(BackendAPI.message.adminEmail, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        })
        result[0] = res.data;
        return result
    } catch (err) {
        result[1] = handleException(err);
        return result
    }
}



/**
 * 
 * @param {string| number} messageId 
 * @returns {Promise<[MessageModel|null, HttpResponse|null]>}
 */
export async function getMessageDetail(messageId) {
    const result = [null, null];
    try {
        const res = await axios.get(`${BackendAPI.message.getMessage}/${messageId}`, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        })
        result[0] = res.data;
        return result
    } catch (err) {
        result[0] = handleException(err);
        return result;
    }
}



export async function sendMessage(subject, description, reciverId) {
    try {
        await axios.post(BackendAPI.message.sendMessage, {
            header: subject,
            message: description,
            receiver: reciverId,
            read: false,
            sender_delete: false,
            receiver_delete: false,
        }, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,

            }
        })
        return new HttpResponse({ statusCode: 200, successMsg: "Message sent" })
    } catch (err) {
        return handleException(err)
    }
}



export async function deleteMessage(messageId) {
    try {
        const res = await axios.get(`${BackendAPI.message.deleteMessage}/${messageId}`, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        })
        return new HttpResponse({ statusCode: res.status, successMsg: "Message is removed" })
    } catch (err) {
        return handleException(err)
    }
}