import { Box, Button, Typography } from "@mui/material";
import BaseModal from "../Basemodal/BaseModal";
import React, { useCallback, useEffect, useState } from "react";
import SendReplyModal from "./SendReplyModel";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { useDispatch } from "react-redux";
import { decrementUnreadMessage } from "../../features/HeaderDataSlice";
import { getMessageDetail } from "../../controller/MessageController";


/**
 * @typedef {object} Props
 * @property {import("../../controller/MessageController").MessageModel} message
 * @property {boolean} open
 * @property {()=>void} onClose
 * @property {React.Dispatch<React.SetStateAction<import("../../controller/MessageController").MessageModel[]>> | undefined} setMessageList
 * @property {boolean} isUnreadMessage
 */

/**
 *
 * @param {Props} param
 */
export default function MessageDetailModel({ message, open, onClose, setMessageList, isUnreadMessage = false }) {
    const [showReplyModal, setShowReplyModal] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const handleFetchMessageDetail = useCallback(async () => {
        const res = await getMessageDetail(message.id);
        if (res[0]) {
            if (setMessageList) {
                setMessageList((prevState) => prevState.filter((data) => data.id !== message.id))
                if (isUnreadMessage) {
                    dispatch(decrementUnreadMessage());
                }
            }
        } else {
            handleControllerResultException(res[1], navigate)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.id, navigate, isUnreadMessage])


    useEffect(() => {
        if (open) {
            handleFetchMessageDetail();
        }
    }, [open, handleFetchMessageDetail])

    return (
        <BaseModal onClose={onClose} open={open} sx={{ padding: "1rem 1.5rem" }}>
            <Box sx={{ marginBottom: "1rem", display: "flex", justifyContent: "flex-end" }}>
                <button onClick={() => onClose()}><Close fontSize="10px" /> </button>
            </Box>
            <Typography
                sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    wordBreak: "break-all",
                    marginBottom: "6px"
                }}
            >
                {message.header}
            </Typography>
            <hr />
            <Typography sx={{ marginTop: "6px", wordBreak: "break-all" }}>{message.message}</Typography>
            <Box
                display={"flex"}
                justifyContent={"flex-end"}
                sx={{ marginTop: "1rem" }}
            >
                <Button onClick={() => setShowReplyModal(true)}>Reply</Button>
            </Box>
            <SendReplyModal
                open={showReplyModal}
                onClose={() => setShowReplyModal(false)}
                reciverId={message.sender}
            />
        </BaseModal>
    );
}
