import React from 'react'
import Header from "./Components/NavBar-Sidebar/Header"
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material';
import { EmptyState } from './Components/EmptyStates/EmptyState';
import error from "./Images/empty-states/error404.png"

function Page404() {

    const navigate = useNavigate();

    
  return (
    < >
       <Header />
        <EmptyState 
          btn="Home"
          img={error}
          title="Screen doesn’t exists"
          message="Oops! It seems like you've reached a dead end. The screen you're trying to access, /rdx, doesn't exist within the app."
          btnLink="/dashboard"
        />
    </>
  )
}

export default Page404
;