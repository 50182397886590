import { Checkbox } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { toast } from "react-toastify";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export function Bookmark({ userId, checked }) {
  const cookies = new Cookies();
  const [checkdState, setChecked] = useState(checked);

  async function handleBookmarkClick() {
    setChecked((p) => !p);
    try {
      const res = await axios.get(`/hri_team/user-bookmark/${userId}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      });

      if (res.status !== 200) return toast.error("Something went wrong");
    } catch (error) {
      throw new Error("Can't add into bookmark");
    }
  }

  return (
    <Checkbox
      {...label}
      onClick={handleBookmarkClick}
      checked={checkdState}
      icon={<BookmarkBorderIcon />}
      checkedIcon={<BookmarkIcon />}
    />
  );
}
