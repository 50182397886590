import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import image from "./HRI-home.png";
// import google from "./Google.png";
// import facebook from "./Facebook.png";
import { toast } from "react-toastify";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { signInWithPopup } from "firebase/auth";
// import { auth, provider } from "../../firebase";
// import linkedIn from "../../Images/Linkedin_logo.png";
import Cookies from "universal-cookie";
// import { postRequest } from "../../utils/request";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { isValidEmail } from "../../utils/utils";

const Login = () => {
  const cookies = new Cookies();
  // const dispatch = useDispatch();
  // const [active, setActive] = useState("btn1");

  const [info, setInfo] = useState({
    name: "HRI Intelligence",
    tagline: "Plan your next recruitment here",
    logo: "",
  });
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  //login in
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const data = {
    email: email,
    password: password,
  };

  async function getLandingPageInfo() {
    try {
      const res = await axios.get("profile/landing-page");
      const business = res.data.business_details[0] || null;

      if (business) {
        const name = business.name.length > 1 ? business.name : null;
        const tagline = business.tag_line.length > 1 ? business.tag_line : null;
        setInfo({ logo: business.logo, name, tagline });
      } else {
        throw new Error("Business details not found");
      }
    } catch (err) {
      throw new Error("Can't get landing-page info", err);
    }
  }

  useEffect(() => {
    getLandingPageInfo();
  }, []);

  const LoginUser = (e) => {
    e.preventDefault();
    if (!isValidEmail(email) || password === "") {
      setShowError(true);
    } else {
      axios
        .post(`auth/team/login`, data)
        .then((res) => {
          localStorage.setItem("user", res.data.user.id.toString());
          cookies.set("token", res.data.user.token);
          navigate("/dashboard");
          toast.success("Successfully logged in", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#1ab394", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#BAE8DE" },
          });
        })
        .catch((err) => {
          toast.error("Error logging in", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        });
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  return (
    <div className="grid place-items-center h-screen w-screen">
      <div className="md:mx-2 p-2 md:px-0 lg:mx-4 ">
        <div className="md:flex">
          <div className="hidden md:flex md:flex-col ml-2 w-1/2">
            <div className="flex md:mt-[10%] lg:mt-2">
              <div
                style={{ display: "flex", alignItems: "flex-start", gap: 5 }}
              >
                {info.logo && (
                  <img
                    src={info.logo}
                    style={{
                      width: "auto",
                      maxHeight: 50,
                      backgroundPosition: "cover",
                    }}
                  />
                )}
                <div className="h-max text-2xl">
                  Welcome to <b style={{ color: "#0865B6" }}>{info.name}</b>
                  <p
                    style={{ color: "rgb(1,1,1,0.4)" }}
                    className="text-sm font-semibold"
                  >
                    {info.tagline}
                  </p>
                </div>
              </div>
            </div>

            <div className="lg:mt-10 xl:mt-0 xl:pt-10 mb-10 lg:p-[8%] overflow-hidden">
              <img className="w-0 md:w-full" src={image} alt="" />
            </div>
            <div className="grid justify-end mt-[-10%]">
              <div className="text-xs lg:text-base text-neutral-500">
                Powered by HRI @MyBizmo
              </div>
            </div>
          </div>
          <div className="border-[3px] p-4 md:p-5 lg:px-10 lg:py-16 md:w-1/2 xl:max-w-[40vw] rounded-lg border-[#0865B6] md:ml-6 m-[auto]">
            <div className="grid grid-cols-1 gap-4 md:gap-6 lg:gap-8">
              <div className="text-2xl md:text-3xl lg:text-4xl font-semibold">
                Login as <span style={{ color: "#0865b6" }}>Team</span>
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Email"
                  placeholder="Enter Email ID"
                  id="outlined-size-small"
                  onChange={(e) => setEmail(e.target.value)}
                  error={showError && !isValidEmail(email)}
                />
              </div>
              <div>
                <FormControl variant="outlined" className="m-2 sm:mr-5 w-full">
                  <InputLabel
                    className="mt-[-5px]"
                    htmlFor="outlined-adornment-password"
                  >
                    Enter Password
                  </InputLabel>
                  <OutlinedInput
                    error={showError && password === ""}
                    label="Create Password"
                    className="w-full"
                    type={values.showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          type={values.showPassword ? "text" : "password"}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    required
                  />
                </FormControl>
              </div>
              <div className="">
                <Button
                  fullWidth
                  style={{ backgroundColor: "#0865B6" }}
                  onClick={LoginUser}
                  className="w-full"
                  variant="contained"
                >
                  Login
                </Button>
              </div>
              <div className=" text-sm sm:text-lg md:text-xl lg:text-3xl">
                <Button
                  fullWidth
                  variant="outlined"
                  color="error"
                  style={{ borderColor: "#0865B6" }}
                  onClick={() => navigate("/forgotpass")}
                >
                  Forgot password
                </Button>
              </div>
              {/* ************* */}

              <div className="inline-flex justify-center font-semibold text-xs xl:text-sm text-center">
                <p className="ml-2">
                  By signing you agree to the{" "}
                  <b className="text-[#0865B6]">terms of use</b> and{" "}
                  <b className="text-[#0865B6]">our Policy</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
