import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

/**
 * @typedef {object} Props
 * @property {number} count
 * @property {(searchText:string, page:number)=>void}  onPageChange
 */

/**
 *
 * @param {Props} param
 * @returns
 */
export default function JobPagination({ count, onPageChange }) {
  const [page, setPage] = useState(1);

  /**
   * @type {import('../../features/JobSearchSlice').JobSearchState}
   */
  const { searchText, isSearched } = useDispatch((state) => state.jobSearch);

  function handleChange(value) {
    setPage(value);
    onPageChange(searchText, value);
  }

  useEffect(() => {
    setPage(1)
  }, [isSearched])

  return (
    <Pagination
      count={count}
      page={page}
      color="primary"
      shape="rounded"
      onChange={(_, value) => handleChange(value)}
    />
  );
}
