/**
* @typedef {object} WebinarModel
* @property {number} id
* @property {string} title
* @property {string} date
* @property {string} startTime
* @property {string} relatedSkills
* @property {string} speaker
* @property {string} speakerDesignation
* @property {string} contactEmail
* @property {string} price
* @property {string} description
* @property {boolean} isOnline
* @property {string} onlineMeetingLink
* @property {string} onlineMeetingPasscode
* @property {string} city
* @property {string} state
* @property {string} pin
* @property {string} country
* @property {string} offlineAddress
*
*/

/**
*
* @param {any} data
* @returns {WebinarModel}
*/

export function createWebinarObj(data) {
    return {
    id: data.id,
    title: data.title,
    date: data.date,
    startTime: data.start_time,
    relatedSkills: data.related_skills,
    speaker: data.speaker,
    speakerDesignation: data.speaker_designation,
    contactEmail: data.contact_email,
    isOnline: data.is_online,
    price: data.price !== null ? data.price : "",
    onlineMeetingLink: data.online_meeting_link,
    description:data.description !== null? data.description:"",
    onlineMeetingPasscode:
    data.online_meeting_passcode !== null
    ? data.online_meeting_passcode
    : "",
    city: data.offline_city !== null ? data.offline_city:"",
    state: data.offline_state !== null ? data.offline_city:"",
    country: data.offline_country !== null ? data.offline_country :"",
    pin: data.offline_pin !== null ? data.offline_pin :"",
    offlineAddress: data.offline_address !== null ? data.offline_address : "",
    };
    }
    