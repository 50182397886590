import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {{notificationList:import("../model/BroadcastMessageModel").BroadcastMessageModel[]}}
 */

const initialState = {
    notificationList: [],
};

const notificationListSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notificationList = action.payload;
        },
    },
});



export default notificationListSlice.reducer;
export const { setNotifications } = notificationListSlice.actions