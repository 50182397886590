import React, {useEffect, useState} from 'react'
import Header from '../NavBar-Sidebar/Header'
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useSelector} from "react-redux";
import {selectHeader} from "../features/HeaderSlice";
import {Link} from 'react-router-dom';
import Cookies from 'universal-cookie';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    TextField
} from "@mui/material";
import axios from 'axios';

const cookies = new Cookies();
const BillDetails = () => {
    const currentState = useSelector(selectHeader);
    const [billEdit, setEditBill] = useState(false)
    const [amount, setAmount] = useState("")
    const [decs, setDesc] = useState('')
    const [status, setStatus] = useState(false)

    const [note, setNote] = useState('')
    const [id, setId] = useState('')
    const navigate = useNavigate();

    async function updateData(e) {
        e.preventDefault();

        axios
            .put(
                `hri_team/bill/update/${id}`,
                {
                    amount: amount,
                    bill_description: decs,
                    note: note,
                },
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                navigate("/Financials");
                toast.success("Bill edited successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(function (error) {

                if (amount >= 2147483647) {
                    toast.error(`Error: ${error.response.data.errors.amount}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
                } else if (decs === "") {
                    toast.error(`Error: Bill Description field should not be empty`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
                } else {
                    toast.error(`${error.response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
                }
            })


    }

    useEffect(() => {
        setDesc(cookies.get("BillDesc"))
        setNote(cookies.get("BillNotes"))
        setId(cookies.get("BillId"))
        setStatus(cookies.get("BillStatus"))
        setAmount(cookies.get("BillAmount"))
    }, [])
    return (
        <>
            <Header/>
            <div className="bg-[#EDEBEE]    w-full h-screen">
                <div
                   
                >
                    <div className='pt-4'>
                        <div className=" mx-4 rounded-t-lg bg-sky-100 ">
                            <div className="flex items-center justify-between px-5 py-2 rounded-md z-10">
                                <Link to={"/Financials"}>
                                    <div
                                        className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                                        <ArrowBackIosNewIcon style={{height: 20}}/>
                                        <span>Back</span>
                                    </div>
                                </Link>
                                <div>

                                    <ToastContainer/>
                                </div>
                                {!billEdit ?
                                    <Button onClick={() => setEditBill(true)} className='w-auto px-5' size="small"
                                            variant="contained">Edit Bill</Button> :
                                    <Button onClick={() => setEditBill(true)} className='w-auto px-5' size="small"
                                            variant="contained" disabled>Edit Bill</Button>}


                            </div>
                        </div>
                        {!billEdit ? <div className="bg-white px-5  rounded-b-lg py-4 mx-4 z-10">
                            <div>
                                <div className='text-lg font-semibold'>Bill Description :</div>
                                {decs}</div>
                            <div>
                                <div className='text-lg mt-4 font-semibold'>Bill Amount :</div>
                                {amount}</div>
                            <div>
                                <div className='text-lg mt-4 font-semibold'>Status :</div>
                                {status ? "Paid" : "Pending"}</div>


                            <div className='mt-4'>
                                <div className='text-lg font-semibold'>Notes :</div>
                                {note}</div>


                        </div> : <div className="bg-white px-5 md:px-20 rounded-b-lg py-4 mx-4 z-10">

                            <div className='grid  mb-5'>

                                <TextField
                                    id="outlined-basic"
                                    type="number"
                                    label="Amount"
                                    variant="outlined"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => {
                                        setAmount(e.target.value);
                                    }}

                                />
                            </div>
                            <div className='grid gap-y-5'>
                                <div>
                                    <div className='mb-2 font-semibold'>Description :</div>
                                    <textarea

                                        className="h-28  mt-2   2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block  w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                        placeholder="Describe anything in message"
                                        cols="10"
                                        rows="5"
                                        value={decs}
                                        onChange={(e) => setDesc(e.target.value)}
                                    /></div>
                                <div>

                                    <div className='mb-2 font-semibold'>Note :</div>
                                    <textarea

                                        className="h-28  mt-2   2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block  w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                        placeholder="Describe anything in message"
                                        cols="10"
                                        rows="5"
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                </div>
                                <p className="font-semibold">
                                    The Bill has been paid
                                </p>
                            </div>
                            <div className='mt-4'>
                                <Button onClick={updateData} className='w-auto px-5' size="small"
                                        variant="contained">Save</Button>
                            </div>
                        </div>}

                    </div>

                </div>
            </div>
        </>
    )
}

export default BillDetails