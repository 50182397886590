import { useEffect, useState } from "react";
import LogImg from "../Login/TeamLogin.png";

import IconButton from "@mui/material/IconButton";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";


const cookies = new Cookies();

const Signup = () => {
    const [password, setPassword] = useState("");
    const [is_company, setIs_company] = useState(true)
    const [confirmPassword, setConfirmPassword] = useState("");
    const [tnc, setTnc] = useState(false);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const [error1, setError] = useState('')

    async function SignUp(e) {
        e.preventDefault();
        let data = { email, password, is_company };
        if (tnc === true && password === confirmPassword) {
            axios
                .post("auth/user/register", data)
                .then((resp) => {
                    navigate("/VerifySignup");
                    cookies.set("token", resp.data.token);
                    toast.success("Signup successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch(function (error) {
                    notifyE("Login Failed!");
                    if (error.response) {
                        error.response.data.errors.error?.amount.map((item) => setError(item))

                        notifyE(error1 || "Login Failed");
                    }
                });
        }
        if (!tnc) {
            notifyE("Please recheck the form entries!")
        }
    }

    const notifyE = (error1) => toast.error(error1, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const [values, setValues] = useState({
        amount: "",
        password: "",
        weight: "",
        weightRange: "",
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword2 = () => {
        setValues({
            ...values,
            showPassword2: !values.showPassword2,
        });
    };

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const [companyName, setCompanyName] = useState("");
    const [tagline, setTagline] = useState("");
    const [terms, setTerms] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState("");

    const GetDetails = () => {
        axios
            .get(
                `profile/landing-page`,
            )
            .then((res) => {
                setCompanyName(res.data.business_details[0].name);
                setTagline(res.data.business_details[0].tag_line);
                setTerms(res.data.business_details[0].terms);
                setPrivacyPolicy(res.data.business_details[0].privacy_policy);
            })
            .catch((err) => {
                console.log("error: ", err)
            })
    };

    useEffect(() => {
        GetDetails();
    }, []);

    return (
        <div className="grid place-items-center h-screen w-screen">
            <div className="md:mx-2 p-2 md:px-0 lg:mx-4 ">
                <div className="md:flex">
                    <div className="hidden md:flex md:flex-col ml-2 w-1/2">
                        <div className="flex md:mt-16 lg:mt-2">
                            <div className="md:text-lg lg:text-2xl xl:text-3xl lg:mt-1 font-semibold ">
                                Welcome to
                            </div>
                            <div className=" md:text-xl lg:text-3xl xl:text-4xl font-semibold text-[#0865B6]">
                                {companyName}
                            </div>
                        </div>
                        <div className="text-neutral-500 md:text-sm lg:text-lg">
                            {tagline}
                        </div>
                        <ToastContainer />
                        <div className="mt-10 xl:mt-0 xl:pt-10 mb-10  2xl:p-20">
                            <img
                                className="w-0 md:w-full"
                                src={LogImg}
                                alt=""
                            />
                        </div>
                        <div className="grid justify-end">
                            <div className="mt-2 text-xs lg:text-base text-neutral-500">
                                Powered by HRI @MyBizmo
                            </div>
                        </div>
                    </div>
                    <div className="border-[3px] p-4 md:p-5 lg:px-10 md:w-1/2 xl:max-w-[40vw] rounded-lg border-[#0865B6] md:ml-10">
                        <div className="grid grid-cols-1 gap-4">
                            <div className="text-xl md:text-2xl lg:text-4xl">
                                Sign Up as Admin
                            </div>
                            <div className="xl:mt-6">
                                <TextField
                                    className="w-full"
                                    label="Enter Full Name"
                                    id="outlined-size-small"
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    placeholder="Enter Email ID"
                                    id="outlined-size-small"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <FormControl variant="outlined" className="m-2 sm:mr-5 w-full">
                                    <InputLabel
                                        className="mt-[-5px]"
                                        htmlFor="outlined-adornment-password"
                                    >
                                        Create Password
                                    </InputLabel>
                                    <OutlinedInput
                                        label="Create Password"
                                        className="w-full"
                                        type={values.showPassword ? "text" : "password"}
                                        onChange={(e) => setPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    type={values.showPassword ? "text" : "password"}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        required
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl variant="outlined" className="m-2 w-full">
                                    <InputLabel
                                        className="mt-[-5px]"
                                        htmlFor="outlined-adornment-password"
                                    >
                                        Confirm Password
                                    </InputLabel>
                                    <OutlinedInput
                                        label="Confirm Password"
                                        id="new_password"
                                        className="w-full"
                                        type={values.showPassword2 ? "text" : "password"}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    type={values.showPassword ? "text" : "password"}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword2}
                                                    onMouseDown={handleMouseDownPassword2}
                                                    edge="end"
                                                >
                                                    {values.showPassword2 ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        required
                                    />
                                </FormControl>
                            </div>
                            <div className="mt-2 xl:mt-6">
                                <Button fullWidth style={{ backgroundColor: "#0865B6" }} onClick={SignUp} className="w-full" variant="contained">
                                    Sign Up
                                </Button>
                            </div>
                            <div className=" text-sm sm:text-lg md:text-xl lg:text-3xl">
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderColor: "#0865B6" }}
                                    onClick={() => navigate("/")}
                                >
                                    Already have an account? Login
                                </Button>
                            </div>

                            <div className="inline-flex justify-center font-semibold text-xs xl:text-sm text-center">
                                <span>
                                    <input
                                        className=""
                                        type="checkbox"
                                        onChange={(e) => setTnc(e.target.checked)}
                                    />
                                </span>
                                <p className="ml-2">
                                    By signing you agree to the <b className="text-[#0865B6]">terms of use</b> and <b
                                        className="text-[#0865B6]">our Policy</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
