import { message } from "antd";
import { HTTPStatus } from "./helperObj";
import HttpResponse from "../model/HttpResponseModal";

export function handleException(err) {
  switch (err.response.status) {
    case HTTPStatus.BAD_REQUEST:
      return new HttpResponse({
        statusCode: HTTPStatus.BAD_REQUEST,
        errorMsg: "Plase provide all information",
      });
    case HTTPStatus.FORBIDDEN:
      return new HttpResponse({
        statusCode: HTTPStatus.FORBIDDEN,
        errorMsg: err.response.statusText
      });
    default:
      return new HttpResponse({
        statusCode: err.response.status,
        errorMsg: err.response.statusText,
      });
  }
}

/**
 * @param {HttpResponse} exception
 * @param {any} navigate
 */
export function handleControllerResultException(exception, navigate) {
  if (exception.statusCode === 401) {
    localStorage.removeItem("userDetails");
    navigate("/login");
  } else {
    message.error(exception.errorMsg);
  }
}

