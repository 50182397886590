import { Box, Pagination } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import noBill from "../../Images/empty-states/no-bill.png";
import { EmptyState } from '../EmptyStates/EmptyState';
import Spinner from "../Meeting/Spinner";

const cookies = new Cookies();

const BIll = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [totalPages , setTotalPages] = useState(1)
    const navigate = useNavigate();

    async function getData(page = 1) {
        await axios
            .get('hri_team/bill', {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
                params:{
                    page,
                }
            })
            .then((resp) => {
                setData(resp.data.results);
                setTotalPages(Math.ceil(resp.data.count / 10))
                setLoading(false)

            })
            .catch((err) => {
            });

    }

    function reverseArr(input) {
        var ret = new Array();
        for (var i = input.length - 1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }

    const newData = reverseArr(data)
    useEffect(() => {
        if (cookies.get("token")) {
            getData();
        } else {
            navigate("/");
        }

    }, []);
    return (
        <>

            <div className='overflow-auto mx-4 rounded-lg mt-4 shadow  mb-20'>
                {data.length ? (
                    <div>
                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                            <tr className='text-blue-700 '>
                                <th className="w-20 p-3 pl-10 text-lg font-semibold tracking-wide text-center">
                                    Bill ID
                                </th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Bill Date
                                </th>

                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Amount
                                </th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Details
                                </th>
                                {/* <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Delete
                                </th> */}
                            </tr>
                            </thead>


                            <tbody className="divide-y divide-gray-300 text-center">
                            {newData.map((user) => {
                                cookies.set("id", user.id)
                                const date = new Date(user.updated_at.slice(0, 10))
                                const month = date.toLocaleString('default', {month: 'long'});


                                return (
                                    <tr

                                        key={user.id}

                                        className="bg-white  hover:bg-slate-100"

                                    >
                                        <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                            {user.complete_bill_id}


                                        </td>
                                        <td className="p-3  MessageLine text-base text-gray-700 whitespace-nowrap">

                                            {date.getDate()}&nbsp;
                                            {month.slice(0, 3)}&nbsp;
                                            {date.getFullYear()}
                                        </td>

                                        <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                            {user.currency} {user.amount}
                                            {/* <div  onClick={() => getMessage(user.id)}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalCenter" className='bg-slate-100  px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 '> View Message </div> */}
                                        </td>
                                        <td className="p-3  text-base text-gray-700 whitespace-nowrap">

                                            <div onClick={() => {
                                                navigate("/BillDetails");
                                                cookies.set("BillDesc", user.bill_description);
                                                cookies.set("BillNotes", user.note);
                                                cookies.set("BillStatus", user.paid);
                                                cookies.set("BillAmount", user.amount);
                                                cookies.set("BillId", user.id);
                                            }}
                                                className='bg-slate-100 px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer '>
                                                View Details
                                            </div>

                                        </td>
                                        {/* <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                    <div  className='flex justify-center'> <MdDelete className='text-2xl text-red-500 hover:text-3xl'/></div>
                    
                    </td> */}
                                    </tr>

                                )
                            })}
                            </tbody>
                        </table>
                        <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                            marginBottom={"2rem"}
                            marginTop={"2rem"}
                        >
                            <Pagination
                                count={totalPages}
                                color="primary"
                                shape="rounded" 
                                onChange={(e, page) => {
                                    getData(page);
                                }}
                            />
                        </Box>
                    </div>
                    
                ) : (
                    <div>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <EmptyState 
                                btn="Create Bill"
                                img={noBill}
                                title="No Bills Found Yet"
                                message="There are currently no bills or invoices to display. Check back later for updates."
                                btnLink="/AddBill"
                            />
                        )}
                    </div>

                )}


            </div>

        </>
    )
}

export default BIll