import React, {useEffect, useState} from 'react'
import Spinner from "../Meeting/Spinner"
import {useSelector} from "react-redux";
import {selectHeader} from "../features/HeaderSlice";
import Cookies from "universal-cookie";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {MdDelete} from 'react-icons/md'
import noPayout from "../../Images/empty-states/no-bill.png"
import { EmptyState } from '../EmptyStates/EmptyState';

const cookies = new Cookies();

const PaymentDetails = () => {
    const currentState = useSelector(selectHeader);
    const [bankAccount, setBankAccount] = useState(0)
    const [data, setData] = useState([]);
    const [loading , setLoading] = useState(true)
    const navigate = useNavigate();

    async function getData() {
        await axios
            .get("hri_team/paymentdetails", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                setData(resp.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });

    }

    async function deleteMessage(id) {
        await axios
            .delete(`hri_team/paymentdetails/delete/${id}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
            })
            .catch((err) => {
                console.log(err);
            });
        getData()
    };
    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            {data.length ? (
                <div>
                    <div className='text-lg font-semibold mb-4 mx-4 mt-4'>Primary Payment Details :</div>
            <div className='overflow-auto mx-4 rounded-lg  mt-4 shadow  mb-20'>

                {data.length ? (
                    <div>

                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                            <tr className='text-blue-700 '>
                                <th className="w-10 p-3 pl-10 text-lg font-semibold tracking-wide text-center">

                                </th>
                                <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                    Account Number
                                </th>

                                <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                    Account Holder Name
                                </th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Bank Name
                                </th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Details
                                </th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Delete
                                </th>

                            </tr>
                            </thead>


                            <tbody className="divide-y divide-gray-300 text-center">
                            {data.map((user) => {

                                if (user.detail_type === "Bank Account") {

                                    return (

                                        <tr

                                            key={user.id}

                                            className="bg-white  hover:bg-slate-100"

                                        >
                                            <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                <div className='w-12 '>
                                                    <img src={user.logo} alt="..." className=''/>
                                                </div>


                                            </td>
                                            <td className="p-3  MessageLine text-base text-gray-700 whitespace-nowrap">

                                                {user.account_no}
                                            </td>

                                            <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                {user.person_name}

                                            </td>
                                            <td className="p-3  text-base text-gray-700 whitespace-nowrap">

                                                {user.bank_name}

                                            </td>
                                            <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                <div onClick={() => {
                                                    navigate("/PaymentDetailsDetails");
                                                    cookies.set("BillDesc", user.bill_description);
                                                    cookies.set("BillNotes", user.note);
                                                    cookies.set("BillAmount", user.amount);
                                                    cookies.set("BillId", user.id);
                                                }}
                                                     className='bg-slate-100   px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer '> View
                                                    Details
                                                </div>

                                            </td>
                                            <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                <div onClick={() => {
                                                    deleteMessage(user.id);
                                                }} className='flex justify-center'><MdDelete
                                                    className='text-2xl text-red-500 hover:text-3xl'/></div>

                                            </td>
                                        </tr>

                                    )
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='flex justify-center w-full'>
                        <div className='flex justify-center w-full'><Spinner/></div>
                    </div>

                )}


            </div>
            <div className='text-lg font-semibold mb-4 mx-4 '>UPI :</div>
            <div className='overflow-auto mx-4 rounded-lg   shadow  mb-20'>

                {data.length ? (
                    <div>

                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                            <tr className='text-blue-700 '>
                                <th className="w-10 p-3 pl-10 text-lg font-semibold tracking-wide text-center">

                                </th>


                                <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                    Account Holder Name
                                </th>

                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Details
                                </th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Delete
                                </th>

                            </tr>
                            </thead>


                            <tbody className="divide-y divide-gray-300 text-center">
                            {data.map((user) => {

                                if (user.detail_type === "UPI") {

                                    return (

                                        <tr

                                            key={user.id}

                                            className="bg-white  hover:bg-slate-100"

                                        >
                                            <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                <div className='w-12 '>
                                                    <img src={user.logo} alt="..." className=''/>
                                                </div>


                                            </td>


                                            <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                {user.person_name}

                                            </td>

                                            <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                <div onClick={() => {
                                                    navigate("/PaymentDetailsDetails");
                                                    cookies.set("PaymentId", user.id);
                                                }}
                                                     className='bg-slate-100   px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer '> View
                                                    Details
                                                </div>

                                            </td>
                                            <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                <div onClick={() => {
                                                    deleteMessage(user.id);
                                                }} className='flex justify-center'><MdDelete
                                                    className='text-2xl text-red-500 hover:text-3xl'/></div>

                                            </td>
                                        </tr>

                                    )
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='flex justify-center w-full'>
                        <div className='flex justify-center w-full'><Spinner/></div>
                    </div>

                )}


            </div>
            <div className='text-lg font-semibold mb-4 mx-4 '>Wallet :</div>
            <div className='overflow-auto mx-4 rounded-lg   shadow  mb-20'>

                {data.length ? (
                    <div>

                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                            <tr className='text-blue-700 '>
                                <th className="w-10 p-3 pl-10 text-lg font-semibold tracking-wide text-center">

                                </th>


                                <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                    Account Holder Name
                                </th>

                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Details
                                </th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Delete
                                </th>

                            </tr>
                            </thead>


                            <tbody className="divide-y divide-gray-300 text-center">
                            {data.map((user) => {

                                if (user.detail_type === "Wallet") {

                                    return (

                                        <tr

                                            key={user.id}

                                            className="bg-white  hover:bg-slate-100"

                                        >
                                            <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                <div className='w-12 '>
                                                    <img src={user.logo} alt="..." className=''/>
                                                </div>


                                            </td>


                                            <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                {user.person_name}

                                            </td>

                                            <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">

                                                <div onClick={() => {
                                                    navigate("/PaymentDetailsDetails");
                                                    cookies.set("BillId", user.id);
                                                }}
                                                     className='bg-slate-100   px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer '> View
                                                    Details
                                                </div>

                                            </td>
                                            <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                <div onClick={() => {
                                                    deleteMessage(user.id);
                                                }} className='flex justify-center'><MdDelete
                                                    className='text-2xl text-red-500 hover:text-3xl'/></div>

                                            </td>
                                        </tr>

                                    )
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='flex justify-center w-full'>
                        <div className='flex justify-center w-full'><Spinner/></div>
                    </div>

                )}


            </div>
            <div className='text-lg font-semibold mb-4 mx-4 '>Paypal :</div>
            <div className='overflow-auto mx-4 rounded-lg   shadow  mb-20'>

                {data.length ? (
                    <div>

                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                            <tr className='text-blue-700 '>
                                <th className="w-10 p-3 pl-10 text-lg font-semibold tracking-wide text-center">

                                </th>


                                <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                    Account Holder Name
                                </th>
                                <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                    Account Holder Email
                                </th>

                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Details
                                </th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                    Delete
                                </th>

                            </tr>
                            </thead>


                            <tbody className="divide-y divide-gray-300 text-center">
                            {data.map((user) => {

                                if (user.detail_type === "Paypal") {

                                    return (

                                        <tr

                                            key={user.id}

                                            className="bg-white  hover:bg-slate-100"

                                        >
                                            <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                <div className='w-12 '>
                                                    <img src={user.logo} alt="..." className=''/>
                                                </div>


                                            </td>


                                            <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                {user.person_name}

                                            </td>
                                            <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                {user.paypal_mail}

                                            </td>

                                            <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">

                                                <div onClick={() => {
                                                    navigate("/PaymentDetailsDetails");
                                                    cookies.set("BillId", user.id);
                                                }}
                                                     className='bg-slate-100   px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer '> View
                                                    Details
                                                </div>

                                            </td>
                                            <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                <div onClick={() => {
                                                    deleteMessage(user.id);
                                                }} className='flex justify-center'><MdDelete
                                                    className='text-2xl text-red-500 hover:text-3xl'/></div>

                                            </td>
                                        </tr>

                                    )
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='flex justify-center w-full'>
                        <div className='flex justify-center w-full'><Spinner/></div>
                    </div>

                )}


            </div>
                </div>
            ) : (
                <div className='mt-11'>
                    <EmptyState
                    btn="Add Payment"
                    img={noPayout}
                    title="No Payouts Yet"
                    message="Currently, there are no payouts to display. Rest assured, payouts will be shown once they are processed"
                    btnLink="/AddPaymentDetails"
                />
                </div>
                
            )}
            


        </>
    )
}

export default PaymentDetails