/**
 * @typedef {object} BroadcastMessageModel
 * @property {number} id
 * @property {number} senderId
 * @property {string} senderEmail
 * @property {string} header
 * @property {string} message
 */


export function createBroadcastMessageObj(data) {
    /**
     * @type {NotificationModel}
     */
    const result = {
        id: data.id,
        senderId: data.sender,
        senderEmail: data.sender_email,
        header: data.header,
        message: data.message
    }
    return result
}