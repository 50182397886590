import { configureStore } from '@reduxjs/toolkit';
import HeaderReducer from '../Components/features/HeaderSlice'
import InfoReducer from '../Components/features/InfoSlice'
import notificationReducer from "../features/NotificationSlice"
import jobSearchReducer from "../features/JobSearchSlice";
import headerDataReducer from "../features/HeaderDataSlice";
export const store = configureStore({
  reducer: {
    header: HeaderReducer,
    info: InfoReducer,
    notifications: notificationReducer,
    jobSearch: jobSearchReducer,
    headerData: headerDataReducer
  },
});
