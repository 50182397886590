import axios from "axios";
import {
    handleControllerResultException,
    handleException,
} from "../utils/handleException";
import { BackendAPI } from "../constants/backendAPI";
import { createUserApplicationObj } from "../model/UserApplicationModel"
import Cookies from "universal-cookie";
import { createJobApplicationObj } from "../model/JobApplicationModel";
import HttpResponse from "../model/HttpResponseModal";

const cookie = new Cookies();

/**
 *
 * @param {string} applicationId
 * @returns {Promise<[import("../models/UserApplicationModel").UserApplicationModel | null, import("../models/HttpResponse").HttpResponse | null]>}
 */
export async function getApplicationDetail(userId, applicationId = null) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `${BackendAPI.userApplication.applicationDetail}/${userId}`,
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
                params: {
                    applicationId: applicationId,
                },
            }
        );
        result[0] = createUserApplicationObj(res.data);
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 *
 * @param {string} jobId
 * @returns {Promise<[import("../models/UserApplicationModel").UserApplicationModel[]|null, import("../models/HttpResponse").HttpResponse|null]>}
 */
export async function getMatchingApplicantList(jobId) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `${BackendAPI.userApplication.matchingApplicantList}/${jobId}`,
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
            }
        );

        const userList = [];
        for (let x of res.data) {
            userList.push(createUserApplicationObj(x));
        }
        result[0] = userList;
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 * @returns {Promise<[{results:import("../models/JobApplicationModel").JobApplicationModel[], count:number} | null, import("../models/HttpResponse").HttpResponse | null]>}
 */

export async function getApplicantsList(page = 1) {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.applicant.applicantList, {
            headers: {
                Authorization: `Token ${cookie.get("token")}`,
            },
            params: {
                page,
            },
        });
        const applicantArr = [];
        for (let x of res.data.results) {
            applicantArr.push(createJobApplicationObj(x));
        }
        result[0] = {
            results: applicantArr,
            count: res.data.count,
        };
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 * @returns {Promise<[{results:import("../models/JobApplicationModel").JobApplicationModel[], count:number} | null, import("../models/HttpResponse").HttpResponse | null]>}
 */
export async function getApplicationFilterList({ jobName = "", education = "", specialization = "", page = 1 }) {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.applicant.applicationFilterList, {
            params: {
                job_name: jobName,
                degree: education,
                specialization,
                page
            },
            headers: {
                Authorization: `Token ${cookie.get("token")}`,
            }
        })
        const applicantArr = [];
        for (let x of res.data.results) {
            applicantArr.push(createJobApplicationObj(x));
        }
        result[0] = {
            results: applicantArr,
            count: res.data.count,
        };
        return result;
    } catch (err) {
        result[1] = handleException(err)
        return result
    }
}

export async function changeApplicationStatus(applicationId, status) {
    try {
        const res = await axios.post(
            `${BackendAPI.applicant.applicantStatusChange}/${applicationId}`,
            {
                status,
            },
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Status changed");
    } catch (err) {
        return handleException(err);
    }
}

/**
 * @typedef {object} ShareApplicationProps
 * @property {number[]} workExperienceIds
 * @property {number[]} educationIds
 * @property {number[]} companyIds
 * @property {number[]} skillIds
 * @property {number} positionId
 * @property {number} applicantId
 */

/**
 * @param {ShareApplicationProps} props
 */
export async function shareApplication({
    workExperienceIds,
    educationIds,
    companyIds,
    skillIds,
    positionId,
    applicantId,
}) {
    try {
        const res = await axios.post(
            BackendAPI.applicant.shareApplication,
            {
                work_exp: workExperienceIds.toString(),
                skills: skillIds.toString(),
                education_certificate: educationIds.toString(),
                company: companyIds.toString(),
                position: positionId,
                user: applicantId,
            },
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Application is shared");
    } catch (err) {
        return handleException(err);
    }
}

/**
 * @returns {Promise<[{educationList:string[], jobList:string[], specializationList:string[]}| null ,HttpResponse|null]>}
 */
export async function applicationFilterInitialData() {
    const fetchJobList = await axios.get(
        BackendAPI.applicant.applicationFilterJobList,
        {
            headers: {
                Authorization: `Token ${cookie.get("token")}`,
            },
        }
    );

    const fetchEducationList = await axios.get(
        BackendAPI.education.educationList,
        {
            headers: {
                Authorization: `Token ${cookie.get("token")}`,
            },
        }
    );
    const specializationList = await axios.get(
        BackendAPI.specialization.specializationList,
        {
            headers: {
                Authorization: `Token ${cookie.get("token")}`,
            },
        }
    );

    const result = [null, null];
    try {
        const res = await Promise.all([fetchJobList, fetchEducationList, specializationList]);
        result[0] = {
            jobList: res[0].data.data,
            educationList: res[1].data.education_list,
            specializationList: res[2].data.specialization_list
        }
        return result
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}


