import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const WarningModal = ({ open, close, title, content, onclickfunction, cancelText, agreeText }) => {
    return (
        <div>
            <Dialog
                fullWidth
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} size="medium" variant="contained" color="error">{cancelText}</Button>
                    <Button onClick={onclickfunction} size="medium" variant="contained" color="primary">
                        {agreeText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default WarningModal
