import Cookies from "universal-cookie";
import { handleException } from "../utils/dataFetchHelper";
import axios from "axios";
import { BackendAPI } from "../constants/backendAPI";
import HttpResponse from "../model/HttpResponseModal";
import { HTTPStatus } from "../utils/helperObj";

const cookies = new Cookies();

/**
 * @typedef {object} UserModel
 * @property {string} id
 * @property {string} email
 * @property {string} userName
 * @property {string} phoneNumber
 * @property {string} dateOfBirth
 * @property {string} employeeId
 * @property {string} role
 * @property {string} gender
 * @property {string} aboutYou
 * @property {string} profileImage
 * @property {string} country
 * @property {string} city
 * @property {string} state
 * @property {string} pinCode
 * @property {string} address
 * @property {string} faceBookId
 * @property {string} githubId
 * @property {string} linkedinId
 *
 */


export async function fetchUserData() {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.userProfile, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        const data = res.data.user.profile;

        /**
         * @type {UserModel}
         */
        const userObj = {
            id: data.id,
            email: data.email,
            userName: data.name,
            phoneNumber: data.otp_phone,
            dateOfBirth: data.dob,
            employeeId: data.employee_id,
            role: data.role,
            gender: data.gender,
            aboutYou: data.about_you,
            profileImage: data.image,
            country: data.country,
            city: data.city,
            state: data.state,
            address: data.address,
            pinCode: data.pin,
            faceBookId: data.facebook_id,
            linkedinId: data.linkedin_id,
            githubId: data.github_id,
        };
        result[0] = userObj;
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

export async function updateProfileDetails(formData, userId) {
    try {
        await axios.put(`${BackendAPI.updateProfileDetails}/${userId}`, formData, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        return new HttpResponse({
            statusCode: HTTPStatus.SUCCESS,
            successMsg: "Profile is updated",
        });
    } catch (err) {
        return handleException(err);
    }
}
