import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  handleControllerResultException,
  handleException,
} from "../../utils/dataFetchHelper";
import Spinner from "../Message/Spinner";
import Header from "../NavBar-Sidebar/Header";
import JobList from "./JobList";
import JobPagination from "./JobPagination";
import JobSearch from "./JobSearch";
const cookies = new Cookies();

const Jobs = () => {
  const navigate = useNavigate();
  const [card_details, setCard_details] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    GetDetails();
  }, []);

  const GetDetails = (searchText = "", page = 1) => {
    axios
      .get("hri_team/job-list", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
          keyword: searchText,
        },
      })
      .then((res) => {
        setCard_details(res.data.results);
        setTotalPages(Math.ceil(res.data.count / 20));
        setLoading(false);
      })
      .catch((err) => {
        const errDetail = handleException(err);
        handleControllerResultException(errDetail, navigate);
      });
  };

  const cardAction = (item) => {
    navigate(`/jobs/job-details/${item.id}`);
    localStorage.setItem("JobId", item.id);
  };

  return (
    <div className="m-1 md:m-2 lg:m-3 bg-[#EDEBEE]">
      <Header />
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : null}

      <div style={{ paddingBottom: "1rem" }}>
        <Box
          marginX={"2rem"}
          marginTop={"1rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ backgroundColor: "white", py: "10px", px: "1rem", borderRadius: "5px" }}
        >
          <Box>
            <Typography sx={{ color: blue[600], fontWeight: "600", fontSize: "1.2rem" }}>All Jobs</Typography>
          </Box>
          <JobSearch
            onSearch={(searchText) => {
              GetDetails(searchText, 1);
            }}
          />
        </Box>
        <Box marginX={"2rem"}>
          <JobList
            jobList={card_details}
            performAction={true}
            actionButtonText={"view details"}
            actionButtonFunc={cardAction}
            loader={loading}
          />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center" }}
          marginBottom={"2rem"}
          marginTop={"2rem"}
        >
          <JobPagination count={totalPages} onPageChange={GetDetails} />
        </Box>
      </div>

    </div>
  );
};

export default Jobs;
