import { toast } from "react-toastify";

export function toastSuccess(message) {
    toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: { backgroundColor: "#0865b6", color: "white" },
        icon: false,
        progressStyle: { backgroundColor: "#BAE8DE" },
    });
}

export function toastError(message) {
    toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: { backgroundColor: "#FF6347", color: "white" },
        icon: false,
        progressStyle: { backgroundColor: "#FFB1A3" },
    });
}
