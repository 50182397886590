import React, {useState} from 'react'
import Header from '../NavBar-Sidebar/Header'
import {useSelector} from "react-redux";
import {selectHeader} from "../features/HeaderSlice";
import {IoAddCircleOutline} from 'react-icons/io5'
import Button from "@mui/material/Button";
import BIll from './BIll';
import {Link} from 'react-router-dom';
import Payout from './Payout';
import {toast, ToastContainer} from "react-toastify";
import PaymentDetails from './PaymentDetails';



const FinanceHeader = () => {

    const currentState = useSelector(selectHeader);
    const [colorChangeBill, setColorChangeBill] = useState(true)
    const [colorChangePayout, setColorChangePayout] = useState(false)
    const [colorChangePaymentDetails, setColorChangePaymentDetails] = useState(false)


    return (
        <>

            <Header/>

            <div className='bg-[#EDEBEE] w-full h-screen  pb-40   '>
                <div
                    >
                    <div className=" bg-white w-full  h-auto md:flex justify-between">
                        <div
                            className={!currentState?.show ? 'bg-white flex  space-x-1 text-xs md:text-xs lg:text-xl  font-semibold p-1 text-gray-600  md:p-2 ' : "bg-white space-x-1 flex  lg:space-x-4 text-xs md:text-lg xl:text-xl  font-semibold p-1 text-gray-600  md:p-2 "}>

                            <div onClick={() => {
                                setColorChangeBill(true);
                                setColorChangePayout(false);
                                setColorChangePaymentDetails(false)
                            }}
                                 className={colorChangeBill ? 'p-3 rounded-lg md:px-8 lg:py-5 xl:py-3 bg-slate-100 text-blue-700 cursor-pointer' : 'cursor-pointer p-3 rounded-lg md:px-8 lg:py-5 xl:py-3 hover:bg-slate-100 hover:text-blue-700'}>
                                Billing
                            </div>

                            <div onClick={() => {
                                setColorChangeBill(false);
                                setColorChangePayout(true);
                                setColorChangePaymentDetails(false)
                            }}
                                 className={colorChangePayout ? 'p-3 rounded-lg md:px-8 lg:py-5 xl:py-3 bg-slate-100 text-blue-700 cursor-pointer' : 'cursor-pointer p-3 rounded-lg md:px-8 lg:py-5 xl:py-3 hover:bg-slate-100 hover:text-blue-700'}>
                                Payout
                            </div>

                            <div onClick={() => {
                                setColorChangeBill(false);
                                setColorChangePayout(false);
                                setColorChangePaymentDetails(true)
                            }}
                                 className={colorChangePaymentDetails ? 'p-3 rounded-lg md:px-8 lg:py-5 xl:py-3 bg-slate-100 text-blue-700 cursor-pointer' : 'cursor-pointer p-3 rounded-lg md:px-8 lg:py-5 xl:py-3 hover:bg-slate-100 hover:text-blue-700'}>
                                Payment Details
                            </div>


                        </div>

                        <div>

                            <ToastContainer/>
                        </div>
                        <div className="pb-4 md:pb-0  lg:mt-2 xl:mt-1 ml-2 md:ml-4">
                            <div
                                className={!currentState?.show ? 'mt-1 mr-2 md:mt-3 lg:mt-4 md:mr-3 lg:mr-10' : 'mt-1 mr-2 md:mt-4 md:mr-10'}>
                                {colorChangeBill ?
                                    <Link to="/AddBill">
                                        <Button className='w-auto px-5' size="small"
                                                variant="contained"><IoAddCircleOutline className='text-lg mr-2'/>
                                            Create New Bill</Button>
                                    </Link>
                                    : null}
                                {colorChangePaymentDetails ?
                                    <Link to="/AddPaymentDetails">
                                        <Button className='w-auto px-5' size="small"
                                                variant="contained"><IoAddCircleOutline className='text-lg mr-2'/>Add
                                            New Payment</Button>
                                    </Link>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div>
                        {colorChangeBill ?
                            <BIll/> : null}
                        {colorChangePayout ?
                            <Payout/> : null}
                        {colorChangePaymentDetails ?
                            <PaymentDetails/> : null}
                    </div>
                </div>
            </div>
        </>

    )
}

export default FinanceHeader