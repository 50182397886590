import React, { useState } from 'react'
import BaseModal from "../Basemodal/BaseModal";
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { sendContactUsMessage } from '../../controller/ContactController';
import { HTTPStatus } from '../../utils/helperObj';
import { toastSuccess } from '../../utils/notification';
import { handleControllerResultException } from '../../utils/dataFetchHelper';
import { useNavigate } from 'react-router-dom';
import SendIcon from "@mui/icons-material/Send";

export default function ContactUsModal({ open, onClose }) {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSendMessage() {
        setLoading(true)
        const res = await sendContactUsMessage(subject, message);
        setLoading(false)
        if (res.statusCode === HTTPStatus.SUCCESS) {
            toastSuccess(res.successMsg)
            onClose()
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose} sx={{
            padding: "1rem 1.5rem", width: {
                md: "500px",
                xs: "80%"
            }
        }}>
            <Box sx={{ marginBottom: "1rem", display: "flex", justifyContent: "flex-end" }}>
                <button onClick={() => onClose()}><Close fontSize="10px" /> </button>
            </Box>
            <TextField
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                fullWidth
                variant="outlined"
                label="Subject of the message"
                sx={{ margin: "1.4rem 0rem" }}
            />
            <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                label="Message (minimum 10 characters)"
            />
            <Box
                sx={{
                    marginTop: "1.5rem",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    variant="contained"
                    onClick={handleSendMessage}
                    sx={{ width: "7rem", padding: "8px 0px" }}
                    disabled={loading}
                >
                    {loading ? (
                        <CircularProgress size={"1.4rem"} />
                    ) : (
                        <span>
                            <SendIcon sx={{ marginRight: "10px" }} />
                            Send
                        </span>
                    )}
                </Button>
            </Box>
        </BaseModal>
    )
}
