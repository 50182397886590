import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { deleteMessage } from "../../controller/MessageController";
import { useNavigate } from "react-router";
import { HTTPStatus } from "../../utils/helperObj";
import { toastSuccess } from "../../utils/notification";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import BaseModal from "../../Components/Basemodal/BaseModal";


/**
 * @typedef {object} Props
 * @property {number} messageId
 * @property {React.Dispatch<React.SetStateAction<import('../../controller/MessageController').MessageModel[]>>} setMessageList
 */

/**
 * @param {Props} param
 */
export default function DeleteMessage({ setMessageList, messageId }) {
    const [loading, setLoading] = useState(false);
    const [showDeleteMessageWarning, setShowDeleteMessageWarning] =
        useState(false);
    const navigate = useNavigate();
    async function handleRemoveMessage() {
        setLoading(true);
        const res = await deleteMessage(messageId);
        setLoading(false);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            setShowDeleteMessageWarning(false)
            toastSuccess(res.successMsg);
            setMessageList((prevState) =>
                prevState.filter((data) => data.id !== messageId)
            );
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    return (
        <Box>
            <Button
                disabled={loading}
                sx={{ width: "4rem" }}
                color="warning"
                variant="outlined"
                onClick={() => setShowDeleteMessageWarning(true)}
            >
                Delete
            </Button>
            <DeleteMessageConfirmation
                open={showDeleteMessageWarning}
                onClose={() => setShowDeleteMessageWarning(false)}
                onClick={handleRemoveMessage}
                loading={loading}
            />
        </Box>
    );
}

function DeleteMessageConfirmation({ open, onClose, onClick, loading }) {
    return (
        <BaseModal
            open={open}
            onClose={onClose}
            sx={{
                padding: "1rem 1.5rem",
                width: {
                    md: "400px",
                    xs: "70%",
                },
            }}
        >
            <Typography>Do you want to delete this message?</Typography>
            <Box
                marginTop={"1.3rem"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}

            >
                <Button onClick={() => onClose()} sx={{ fontSize: "12px", color: "#212121" }}>Cancel</Button>
                <Button
                    variant="contained"
                    color="error"
                    sx={{ marginLeft: "14px", fontSize: "12px" }}
                    onClick={() => onClick()}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={"1.3em"} /> : "Delete"}
                </Button>
            </Box>
        </BaseModal>
    );
}
