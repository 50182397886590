import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GithubIcon from "@mui/icons-material/GitHub";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { grey } from "@mui/material/colors";
import Header from "../NavBar-Sidebar/Header";

const cookies = new Cookies();

const Profile = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [empId, setEmpId] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState("");
  // const [status, setStatus] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [country, setCountry] = useState("");
  const [add, setAdd] = useState("");
  const [github_id, setGithub_id] = useState("");
  const [linkedin_id, setLinkedin_id] = useState("");

  async function getData() {
    await axios
      .get(`auth/user/profile`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        setEmail(resp.data.user.email);
        setId(resp.data.user.id);
        setName(resp.data.user.profile.name);
        cookies.set("profileName", resp.data.user.profile.name);
        setPhone(resp.data.user.profile.phone_number);
        setDob(resp.data.user.profile.dob);
        setEmpId(resp.data.user.profile.employee_id);
        setRole(resp.data.user.profile.role);
        setGender(resp.data.user.profile.gender);
        setAbout(resp.data.user.profile.about_you);
        setImage(resp.data.user.profile.image);
        // setStatus(resp.data.user.profile.status);
        setCity(resp.data.user.profile.city);
        setCountry(resp.data.user.profile.country);
        setState(resp.data.user.profile.state);
        setPin(resp.data.user.profile.pin);
        setAdd(resp.data.user.profile.address);
        setGithub_id(resp.data.user.profile.github_id);
        setLinkedin_id(resp.data.user.profile.linkedin_id);
        cookies.set("profileImage", resp.data.user.profile.image);
        cookies.set("otp", resp.data.user.profile.otp);
        cookies.set("id", resp.data.user.profile.id);
      })
      .catch((err) => {
        toast.info(err.message);
        console.log(err);
      });
  }

  useEffect(() => {
    if (cookies.get("token")) {
      getData();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="bg-[#EDEBEE] h-screen overflow-auto">
      <Header />
      <Container
        maxWidth="md"
        sx={{
          marginTop: "4rem",
          marginBottom: "4rem",
        }}
      >
        <Grid container columns={{ md: 12, xs: 6 }} spacing={5}>
          <Grid item md={6} xs={3}>
            <Typography variant="h5" fontWeight="bold" color="rgb(8, 101, 182)">
              My Profile
            </Typography>
          </Grid>
          <Grid item md={6} xs={3}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => navigate("/EditProfile")}
                style={{
                  backgroundColor: "#F1F1F1",
                  borderRadius: "10px",
                  color: "rgb(8, 101, 182)",
                }}
              >
                Edit <EditIcon style={{ fontSize: "medium" }} />
              </Button>
            </Box>
          </Grid>

          <Grid item md={12} xs={6}>
            <Box display="flex" alignItems="center">
              <Avatar
                src={image}
                alt="profile"
                sx={{ width: "60px", height: "60px" }}
              />
              <Typography
                variant="body1"
                fontSize="1.5rem"
                textTransform="uppercase"
                ml="20px"
              >
                {name ? name : "Your Name"}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={4} xs={3} sx={{ textAlign: "left" }}>
            <Typography>Email ID</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {email ? email : "Email"}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={3}
            sx={{
              textAlign: {
                md: "center",
                xs: "right",
              },
            }}
          >
            <Typography>Phone no</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {phone ? phone : "Phone No."}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={3}
            sx={{
              textAlign: {
                md: "right",
                xs: "left",
              },
            }}
          >
            <Typography>Employee Id</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {empId ? empId : "No employ ID"}
            </Typography>
          </Grid>

          <Grid
            item
            md={4}
            xs={3}
            sx={{
              textAlign: {
                md: "left",
                xs: "right",
              },
            }}
          >
            <Typography>Date of Birth</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {dob ? dob : "Date of birth"}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={3}
            sx={{
              textAlign: {
                md: "center",
                xs: "left",
              },
            }}
          >
            <Typography>Designation</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {role ? role : "eg. Developer, Manager"}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={3}
            sx={{
              textAlign: "right",
            }}
          >
            <Typography>Country</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {country ? country : "eg. India"}
            </Typography>
          </Grid>

          <Grid item md={4} xs={3} sx={{ textAlign: "left" }}>
            <Typography>Gender</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {gender ? gender : "select gender"}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={3}
            sx={{
              textAlign: {
                md: "center",
                xs: "right",
              },
            }}
          >
            <Typography>State</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {state ? state : "eg. Karnataka, dehli"}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={3}
            sx={{
              textAlign: {
                md: "right",
                xs: "left",
              },
            }}
          >
            <Typography>Pin code</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {pin ? pin : "Pin code"}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={3}
            sx={{
              textAlign: {
                md: "left",
                xs: "right",
              },
            }}
          >
            <Typography>City</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {city ? city : "eg. Banglore, dehli"}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={3}
            sx={{
              textAlign: {
                md: "right",
                xs: "left",
              },
            }}
          >
            <Typography>Address</Typography>
            <Typography fontSize={20} fontWeight="bold">
              {add ? add : "Your Address..."}
            </Typography>
          </Grid>

          <Grid item md={12} xs={6}>
            <Typography>About you</Typography>
            <Typography
              whiteSpace="pre-wrap"
              variant="body1"
              sx={{ wordBreak: "break-all" }}
            >
              {about ? about : "About you..."}
            </Typography>
          </Grid>
          {linkedin_id && (
            <Grid item xs={2} md={2}>
              <a href={`${linkedin_id}`} target="_blank" rel="noreferrer">
                <Button
                  variant="contained"
                  endIcon={<LinkedInIcon sx={{ color: "#FFFFFF" }} />}
                >
                  LinkedIn
                </Button>
              </a>
            </Grid>
          )}
          {github_id && (
            <Grid item xs={2} md={2}>
              <a href={`${github_id}`} target="_blank" rel="noreferrer">
                <Button
                  variant="contained"
                  sx={{ backgroundColor: grey[900] }}
                  endIcon={<GithubIcon sx={{ color: "#FFFFFF" }} />}
                >
                  GIthub
                </Button>
              </a>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Profile;
