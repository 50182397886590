import { Box, Button, CircularProgress, Modal, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
    getApplicationDetail,
    shareApplication,
} from "../../../controller/UserApplicationController";
import { useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../../utils/handleException";
import WorkExperienceSelector from "./WorkExperienceSelector";
import {
    ArrowBackIos,
    ArrowForwardIos,
    CurrencyExchange,
    Warning,
} from "@mui/icons-material";
import { red } from "@mui/material/colors";
import SkillsSelector from "./SkillsSelector";
import EducationSelector from "./EducationSelector.jsx";
import { RiShareForward2Fill } from "react-icons/ri";
import CompanySelector from "./CompanySelector";
import { HTTPStatus } from "../../../utils/helperObj";
import { message } from "antd";
/**
 * @typedef {object} Props
 * @property {number} applicantId
 * @property {number} applicationId
 * @property {number} positionId
 * @property {boolean} open
 * @property {()=>void} onClose
 *
 */

/**
 * @param {Props} props
 */
export default function ApplicationShareModal({
    applicantId,
    applicationId,
    positionId,
    open,
    onClose,
}) {
    const [applicantDetails, setApplicationDetails] =
        useState(applicantDetailsType);
    const [currentScreen, setCurrentScreen] = useState(screenType);
    const [workExperinceIds, setWorkExperinceIds] = useState([]);
    const [skillIds, setSkillIds] = useState([]);
    const [educationIds, setEducationIds] = useState([]);
    const [companyIds, setCompanyIds] = useState([]);
    const [sending, setSending] = useState(false)

    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();

    const handleApplicationDetails = useCallback(
        async (applicantId, applicationId) => {
            const res = await getApplicationDetail(applicantId, applicationId);
            if (res[0] !== null) {
                setApplicationDetails(res[0]);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        []
    );

    useEffect(() => {
        if (applicantId > 0 && applicationId > 0) {
            handleApplicationDetails(applicantId, applicationId);
        }
    }, [handleApplicationDetails, applicantId, applicationId]);

    function handleForward() {
        setShowError(false);
        if (currentScreen === "workExperience") {
            if (workExperinceIds.length > 0) {
                setCurrentScreen("skills");
            } else {
                setShowError(true);
            }
        } else if (currentScreen === "skills") {
            if (skillIds.length > 0) {
                setCurrentScreen("education");
            } else {
                setShowError(true);
            }
        } else if (currentScreen === "education") {
            if (educationIds.length > 0) {
                setCurrentScreen("company");
            } else {
                setShowError(true);
            }
        }
    }

    function handleBackword() {
        setShowError(false);
        currentScreen === "company" && setCurrentScreen("education");
        currentScreen === "education" && setCurrentScreen("skills");
        currentScreen === "skills" && setCurrentScreen("workExperience");
    }

    async function handleShareApplication() {
        setShowError(false);
        if (companyIds.length > 0) {
            setSending(true)
            const res = await shareApplication({
                workExperienceIds: workExperinceIds,
                educationIds,
                companyIds,
                skillIds,
                applicantId,
                positionId,
            });
            setSending(false)
            if (res.statusCode === HTTPStatus.CREATED) {
                message.success(res.success)
                onClose();
            } else {
                handleControllerResultException(res, navigate)
            }
        } else {
            setShowError(true);
        }
    }


    function handleClose() {
        setWorkExperinceIds([])
        setSkillIds([])
        setEducationIds([])
        setCompanyIds([])
        setCurrentScreen("workExperience")
        setShowError(false)
        onClose()
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    maxHeight: 500,
                    overflow: "auto",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Box
                    marginBottom={"1rem"}
                    display={"flex"}
                    justifyContent={"space-between"}
                >
                    {currentScreen !== "workExperience" && (
                        <Box
                            isplay={"flex"}
                            justifyContent={"flex-start"}
                            width={"100%"}
                        >
                            <Button onClick={handleBackword}>
                                <ArrowBackIos />
                                Previos
                            </Button>
                        </Box>
                    )}
                    {currentScreen !== "company" && (
                        <Box
                            display={"flex"}
                            justifyContent={"flex-end"}
                            width={"100%"}
                        >
                            <Button onClick={handleForward}>
                                Next
                                <ArrowForwardIos />
                            </Button>
                        </Box>
                    )}
                    {currentScreen === "company" && (
                        <Box
                            display={"flex"}
                            justifyContent={"flex-end"}
                            width={"100%"}
                        >
                            <Button disabled={sending} variant="contained" onClick={handleShareApplication} sx={{ width: "100px", height: "40px" }}>
                                {sending ? <CircularProgress size={"15px"} /> : <>
                                    <RiShareForward2Fill
                                        style={{ marginRight: "5px" }}
                                    />
                                    Share</>}
                            </Button>
                        </Box>
                    )}
                </Box>
                {showError && (
                    <Typography fontSize={"16px"} color={red[600]} sx={{ marginBottom: "10px" }}>
                        {" "}
                        <Warning color="error" /> Please select at least one
                        item
                    </Typography>
                )}
                {currentScreen === "workExperience" && (
                    <WorkExperienceSelector
                        workExperinceIds={workExperinceIds}
                        setWorkExperinceIds={setWorkExperinceIds}
                        workExperinceList={applicantDetails.workExperience}
                    />
                )}
                {currentScreen === "skills" && (
                    <SkillsSelector
                        skillIds={skillIds}
                        setSkillIds={setSkillIds}
                        skillList={applicantDetails.userSkills}
                    />
                )}
                {currentScreen === "education" && (
                    <EducationSelector
                        educationIds={educationIds}
                        setEducationIds={setEducationIds}
                        educationList={applicantDetails.certificates}
                    />
                )}
                {currentScreen === "company" && (
                    <CompanySelector
                        companyIds={companyIds}
                        setCompanyIds={setCompanyIds}
                    />
                )}
            </Box>
        </Modal>
    );
}

/**
 *  @type {import("../../../models/UserApplicationModel").UserApplicationModel}
 */

const applicantDetailsType = {
    id: 0,
    firstName: "",
    lastName: "",
    profilePic: "",
    martialStatus: "",
    gender: "",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    address: "",
    currentAddress: "",
    pinCode: 0,
    city: "",
    state: "",
    country: "",
    education: {
        title: "",
        course: "",
        courseType: "",
        instituteName: "",
        isCompleted: false,
        passingYear: "",
        specialization: "",
    },
    resume: {
        resumeFileLink: "",
        resumeFileName: "",
        resumeVideoFileName: "",
        resumeVideoLink: "",
    },
    language: "",
    linkedinLink: "",
    githubLink: "",
    status: "",
    workExperience: [],
    userSkills: [],
    certificates: [],
};

/**
 * @type {"workExperience" | "skills" | "education" | "company"}
 */

const screenType = "workExperience";
