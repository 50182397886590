import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Modal,
    Tooltip,
    Typography,
  } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { grey } from "@mui/material/colors";
import picture from "../../Images/4041088.png"
import RichDescription from "../jobDescription/RichDescription";
import { User_Fe_url } from "../..";
import ActiveJobHeader from "../NavBar-Sidebar/ActiveJobHeader";

const cookies = new Cookies();

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    minWidth: 300
  };

const ActiveJobs = () => {
    const navigate = useNavigate();
    const {id} = useParams()
    const [info, setInfo] = useState([]);

    const GetCompanyDetails = () => {
        axios
          .get(`hri_team/job-detail-open/${id}`)
          .then((res) => {
            setInfo(res.data);
          })
          .catch((err) => {
            
          });
      };
    
      const position = {
        position: localStorage.getItem("JobId"),
      };

      const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    GetCompanyDetails();
  }, []);

  const Apply_Fe_Url = `${User_Fe_url}applications/job-detail/${id}`

  return (
    <div>
      <ActiveJobHeader />
    <div className="bg-white p-2 rounded-lg m-2 md:m-4">
      {/* <div className="flex justify-between">
        <p
          className="text-[#0865B6] rounded-md w-28  hover:bg-[#F1F1F1] py-1 px-3 text-lg font-semibold cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon
            className="ml-1 mr-1 mb-1"
            style={{ fontSize: "large" }}
          />
          Back
        </p>
        <p
          className="text-[#0865B6] rounded-md w-28  hover:bg-[#F1F1F1] py-1 px-3 text-lg font-semibold cursor-pointer"
          onClick={copyLink}
        >
          Share Job
        </p>
      </div> */}
      <div className="flex justify-between">
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        {info.map((item) => (
          <div key={item.id}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >

              <Box display="flex" alignItems="center" component="div">
                <Avatar
                  src={item.company_logo || picture}
                  alt={item.company_name || "Company"}
                  sx={{ width: 80, height: 80 }}
                />

                <Box component="div" ml={2}>
                  <Typography fontFamily="Roboto" variant="h5" fontWeight="500">

                    {item.position_name ? item.position_name : "not available"}
                  </Typography>

                  {item.company_name && (
                    <Typography color={grey[600]} fontFamily="Roboto">
                      {item.company_name}
                    </Typography>
                  )}
                </Box>
              </Box>

              {/* {item.is_assessment === true ? (
                <div

                  align="center"
                  className="cursor-pointer"
                  onClick={() => {
                    handleOpen();
                    localStorage.setItem("AssessmentID", item.id);
                  }}
                >
                  <Tooltip title="Please Complete the Assessment to apply for Job">
                    <p
                      className="py-2 text-[#0865B6]  px-3 font-semibold rounded-md hover:bg-[#F1F1F1]
                                             text-sm
                                             md:text-base
                                             lg:text-lg"
                    >
                      Start Assessment
                    </p>
                  </Tooltip>
                </div>
              ) : (
                <Button
                  color="primary"
                  onClick={() => ApplyForJob(`${item.company}`)}
                >
                  <Typography fontFamily="Roboto" fontWeight="500">
                    {" "}
                    Apply Now
                  </Typography>
                </Button>
              )} */}
            </Box>
            <Box mt={5} mb={12} component="div">
              <Grid
                container
                justifyContent="center"
                spacing={4}
                columns={{ xs: 6, md: 12 }}
              >
                <Grid item xs={3} md={4}>
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Position
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.position_name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} md={4}>
                  <Box
                    sx={{
                      textAlign: {
                        md: "center",
                        xs: "right",
                      },
                    }}
                  >
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Experience
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.experience}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} md={4}>
                  <Box
                    sx={{
                      textAlign: {
                        md: "right",
                        xs: "left",
                      },
                    }}
                  >
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Employment Type
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.employment_type}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={3} md={4}>
                  <Box
                    sx={{
                      textAlign: {
                        md: "left",
                        xs: "right",
                      },
                    }}
                  >
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Location
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.location}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={3} md={4}>
                  <Box
                    sx={{
                      textAlign: {
                        md: "center",
                        xs: "left",
                      },
                    }}
                  >
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Stipend / Salary
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.stipend}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} md={4}>
                  <Box sx={{ textAlign: "right" }}>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Education
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.criteria}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={3} md={6}>
                  <Box
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Industry Type
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.industry_type
                        ? item.industry_type
                        : "not available"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} md={6}>
                  <Box
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Role Category
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.role_category_name
                        ? item.role_category_name
                        : "not available"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Role
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                    >
                      {item.role_sub_category_name
                      ? item.role_sub_category_name :
                      "Not Available"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Box
                    sx={{
                      textAlign:"left"
                    }}
                  >
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Functional Area
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      variant="h5"
                      fontSize={{
                        md:20,
                        xs:18
                      }}
                      color={grey[800]}
                 
                    >
                      {item.functional_area
                        ? item.functional_area
                        : "not available"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Box>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Skills
                    </Typography>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      alignItems="center"
                      gap={2}
                    >
                      {item.skills.split(",").map((item, index) => (
                        <Typography
                          fontFamily="Roboto"
                          fontWeight="500"
                          fontSize={18}
                          color={grey[800]}
                          key={index}
                        >
                          {item}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Box>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Job Description
                    </Typography>

                    <Box sx={{ fontFamily: "Roboto", fontSize: "1rem" }}>
                      {item.job_description ? (
                        <RichDescription>{item.job_description}</RichDescription>
                      ) : (
                        "not available"
                      )}
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={6} md={12}>
                  <Box>
                    <Typography
                      fontFamily="Roboto"
                      fontWeight="500"
                      color={grey[600]}
                    >
                      Company Information
                    </Typography>
                    <Typography
                      fontFamily="Roboto"
                      fontSize={18}
                      color={grey[800]}
                      textTransform="capitalize"
                    >
                      {item.company_details
                        ? item.company_details
                        : "not available"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={12}>
                  <a href={item.company_url ? item.company_url : "#"}>
                    <Button variant="outlined">Company home page</Button>
                  </a>
                </Grid>
              </Grid>
            </Box>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{ ...style, px: { md: 10, xs: 2 }, py: { md:12 , xs:6} }} >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {item.is_assessment === true ? (
                    item.assessment_details.name
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {item.is_assessment === true ? (
                    item.assessment_details.description
                  ) : (
                    <></>
                  )}
                </Typography>
                <div className="flex flex-row mt-3 gap-4">
                  <div>
                    <Button onClick={handleClose}>Cancel</Button>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => navigate("survey-form")}
                      sx={{ whiteSpace: "nowrap"}}
                    >
                      Start Assessment
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        ))}
      </Container>
      <a href={Apply_Fe_Url}>
        <Button
          className="w-auto px-5 mt-11"
          size="small"
          variant="contained"
        >
          Apply Now
        </Button>
        </a>
      </div>
    </div>
    </div>
  )
}

export default ActiveJobs
