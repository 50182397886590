import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import PDF from "../Images/pdf.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {getRequest, postRequest} from "../utils/request";
import Header from "./NavBar-Sidebar/Header";
import {useSelector} from "react-redux";
import {selectHeader} from "./features/HeaderSlice";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const PaymentAddForm = () => {
    const navigate = useNavigate();
    const currentState = useSelector(selectHeader);
    const paymentMode = [
        {
            id: 1,
            value: "Bank Account",
        },
        {
            id: 2,
            value: "UPI",
        },
        {
            id: 3,
            value: "Wallet",
        },
        {
            id: 4,
            value: "Paypal",
        },
    ];

    const [mode, setMode] = useState("");

    const [bankname, setBankname] = useState("");
    const [accountno, setAccountno] = useState("");
    const [name, setName] = useState("");
    const [branch, setBranch] = useState("");
    const [acType, setAcType] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [mmid, setMmid] = useState("");
    const [vpa, setVpa] = useState("");

    const [upiname, setUpiname] = useState("");
    const [upiId, setUpiId] = useState("");

    const [walletName, setWalletName] = useState("");
    const [walletPhoneNumber, setWalletPhoneNumber] = useState("");

    const [paypalEmail, setPaypalEmail] = useState("");

    const create = () => {
        let formField = new FormData();

        formField.append("detail_type", mode);

        formField.append("bank_name", bankname);
        formField.append("account_no", accountno);
        formField.append("person_name", name);
        formField.append(" branch_address", branch);
        formField.append("account_type", acType);
        formField.append("ifsc", ifsc);
        formField.append("mmid", mmid);
        formField.append("vpa", vpa);

        formField.append("upi_id_name", upiname);
        formField.append("upi_id", upiId);

        formField.append("wallet_name", walletName);
        formField.append("wallet_phone_no", walletPhoneNumber);

        formField.append("paypal_mail", paypalEmail);

        axios
            .post(`hri_team/paymentdetails/create`, formField, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {
                // toast.success("Getting Response");
                navigate("/Financials");
                toast.success("Payment added successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((err) => {
                toast.error(`Error: ${err.response.data.errors.paypal_mail}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#FF6347", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#FFB1A3"},
                });
                // toast.error("Some error occurred");
            });
        // postRequest(
        //   `hri_team/paymentdetails/create`,
        //   formField,

        // );
    };


    return (
        <>
            <Header/>

            <div className='bg-[#EDEBEE] w-full h-screen    '>
                <div
                    >
                    <div className="bg-white p-4 space-y-5 rounded-md m-4">
                        <div className="flex flex-col space-y-2 max-w-[30vw]">
                            <FormControl sx={{minWidth: 130}}>
                                <InputLabel>Payment Mode</InputLabel>
                                <Select
                                    value={mode}
                                    label="Payment Mode"
                                    onChange={(e) => {
                                        setMode(e.target.value);
                                    }}
                                >
                                    {paymentMode.map((item, index) => (
                                        <MenuItem value={item.value} key={index}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>

                            <ToastContainer/>
                        </div>
                        <div
                            className={`grid grid-cols-2 gap-4 mt-3 ${
                                mode === "Bank Account" ? "block" : "hidden"
                            }`}
                        >
                            <FormControl sx={{minWidth: 150}}>
                                <InputLabel>Bank Name</InputLabel>
                                <Select
                                    value={bankname}
                                    label="Bank Name"
                                    onChange={(e) => {
                                        setBankname(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"State Bank Of India"}>
                                        State Bank Of India
                                    </MenuItem>
                                    <MenuItem value={"ICICI Bank"}>ICICI Bank</MenuItem>
                                    <MenuItem value={"HDFC Bank"}>HDFC Bank</MenuItem>
                                    <MenuItem value={"Canara Bank"}>Canara Bank</MenuItem>
                                    <MenuItem value={"Axis Bank"}>Axis Bank</MenuItem>
                                    <MenuItem value={"Bank of Baroda"}>Bank of Baroda</MenuItem>
                                    <MenuItem value={"Central Bank of India"}>
                                        Central Bank of India
                                    </MenuItem>
                                    <MenuItem value={"City Bank"}>City Bank</MenuItem>
                                    <MenuItem value={"Federal Bank"}>Federal Bank</MenuItem>
                                    <MenuItem value={"Indian Bank"}>Indian Bank</MenuItem>
                                    <MenuItem value={"Indusind Bank"}>Indusind Bank</MenuItem>
                                    <MenuItem value={"Kotak Mahindra Bank"}>
                                        Kotak Mahindra Bank
                                    </MenuItem>
                                    <MenuItem value={"South Indian Bank"}>South Indian Bank</MenuItem>
                                    <MenuItem value={"Standard Chartered"}>Standard Chartered</MenuItem>
                                    <MenuItem value={"Union Bank of India"}>
                                        Union Bank of India
                                    </MenuItem>
                                    <MenuItem value={"Yes Bank"}>Yes Bank</MenuItem>
                                    <MenuItem value={"others"}>Others</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                id="outlined-basic"
                                label="Account Number"
                                variant="outlined"
                                placeholder="Enter the account number"
                                value={accountno}
                                onChange={(e) => {
                                    setAccountno(e.target.value);
                                }}
                            />

                            <TextField
                                id="outlined-basic"
                                label="Account Holder’s Name"
                                variant="outlined"
                                placeholder="Enter the account holder’s name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />

                            <TextField
                                id="outlined-basic"
                                label="Bank Branch"
                                variant="outlined"
                                placeholder="Enter the bank branch"
                                value={branch}
                                onChange={(e) => {
                                    setBranch(e.target.value);
                                }}
                            />

                            <FormControl sx={{minWidth: 150}}>
                                <InputLabel>Account Type</InputLabel>
                                <Select
                                    value={acType}
                                    label="Account Type"
                                    onChange={(e) => {
                                        setAcType(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"Current Account"}>Current Account</MenuItem>
                                    <MenuItem value={"Savings Account"}>Savings Account</MenuItem>
                                    <MenuItem value={"Salary Account"}>Salary Account</MenuItem>
                                    <MenuItem value={"Fixed deposit Account"}>
                                        Fixed deposit Account
                                    </MenuItem>
                                    <MenuItem value={"Recurring deposit Account"}>
                                        Recurring deposit Account
                                    </MenuItem>
                                    <MenuItem value={"NRO Accounts"}>NRO Accounts</MenuItem>
                                    <MenuItem value={"FCNR Accounts"}>FCNR Accounts</MenuItem>
                                    <MenuItem value={"Others"}>Others</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                id="outlined-basic"
                                label="IFSC Code"
                                variant="outlined"
                                placeholder="Enter the IFSC code"
                                value={ifsc}
                                onChange={(e) => {
                                    setIfsc(e.target.value);
                                }}
                            />

                            <TextField
                                id="outlined-basic"
                                label="MMID"
                                variant="outlined"
                                placeholder="Enter the MMID"
                                value={mmid}
                                onChange={(e) => {
                                    setMmid(e.target.value);
                                }}
                            />

                            <TextField
                                id="outlined-basic"
                                label="VPA"
                                variant="outlined"
                                placeholder="Enter the VPA"
                                value={vpa}
                                onChange={(e) => {
                                    setVpa(e.target.value);
                                }}
                            />
                        </div>

                        <div
                            className={`grid grid-cols-2 gap-4 mt-3 ${
                                mode === "UPI" ? "block" : "hidden"
                            }`}
                        >
                            <FormControl sx={{minWidth: 150}}>
                                <InputLabel>UPI Payment Mode</InputLabel>
                                <Select
                                    value={upiname}
                                    label="UPI Payment Mode"
                                    onChange={(e) => {
                                        setUpiname(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"PayTm"}>PayTm</MenuItem>
                                    <MenuItem value={"Google Pay"}>Google Pay</MenuItem>
                                    <MenuItem value={"Amazon Pay"}>Amazon Pay</MenuItem>
                                    <MenuItem value={"BHIM"}>BHIM</MenuItem>
                                    <MenuItem value={"PhonePe"}>PhonePe</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                id="outlined-basic"
                                label="UPI ID"
                                variant="outlined"
                                placeholder="Enter the UPI ID"
                                value={upiId}
                                onChange={(e) => {
                                    setUpiId(e.target.value);
                                }}
                            />

                            <TextField
                                id="outlined-basic"
                                label="UPI ID Holder's Name"
                                variant="outlined"
                                placeholder="Enter the UPI ID holder's name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>

                        <div
                            className={`grid grid-cols-2 gap-4 mt-3 ${
                                mode === "Wallet" ? "block" : "hidden"
                            }`}
                        >
                            <FormControl sx={{minWidth: 150}}>
                                <InputLabel>Digital Wallet</InputLabel>
                                <Select
                                    value={walletName}
                                    label="Digital Wallet"
                                    onChange={(e) => {
                                        setWalletName(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"PayTm"}>PayTm</MenuItem>
                                    <MenuItem value={"Google Pay"}>Google Pay</MenuItem>
                                    <MenuItem value={"Amazon Pay"}>Amazon Pay</MenuItem>
                                    <MenuItem value={"BHIM"}>BHIM</MenuItem>
                                    <MenuItem value={"PhonePe"}>PhonePe</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                id="outlined-basic"
                                label="Contact Number"
                                variant="outlined"
                                placeholder="Enter the contact number"
                                value={walletPhoneNumber}
                                onChange={(e) => {
                                    setWalletPhoneNumber(e.target.value);
                                }}
                            />

                            <TextField
                                id="outlined-basic"
                                label="Wallet Holders's Name"
                                variant="outlined"
                                placeholder="Enter the Wallet holder's name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>

                        <div
                            className={`grid grid-cols-2 gap-4 mt-3 ${
                                mode === "Paypal" ? "block" : "hidden"
                            }`}
                        >
                            <TextField
                                id="outlined-basic"
                                label="PayPal Email ID"
                                type="email"
                                variant="outlined"
                                placeholder="Enter the PayPal ID"
                                value={paypalEmail}
                                onChange={(e) => {
                                    setPaypalEmail(e.target.value);
                                }}
                                required
                            />

                            <TextField
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                                placeholder="Enter the name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>

                        <div className="flex items-center space-x-5">
                            <Button
                                variant="contained"
                                startIcon={<SendIcon/>}
                                onClick={() => {
                                    create();
                                }}
                                disabled={!name || !mode}
                            >
                                Save
                            </Button>
                            <Button variant="outlined" startIcon={<CloseIcon/>} onClick={() => navigate("/Financials")}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentAddForm;
