import LogImg from "../Login/TeamLogin.png";
import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

const ForgotPass = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
    }, [email]);

    function SendOtp(e) {
        e.preventDefault();
        let data = { email };
        axios
            .post("auth/user/forgot/password", data, {})
            .then((res) => {
                navigate("/EnterOtp");
                cookies.set("email", data.email);
            })
            .catch((err) => {
                alert("plz enter a valid email");
            });
    }

    const [companyName, setCompanyName] = useState("");
    const [tagline, setTagline] = useState("");
    const [terms, setTerms] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState("");

    const GetDetails = () => {
        axios
            .get(`profile/landing-page`,)
            .then((res) => {
                setCompanyName(res.data.business_details[0].name);
                setTagline(res.data.business_details[0].tag_line);
                setTerms(res.data.business_details[0].terms);
                setPrivacyPolicy(res.data.business_details[0].privacy_policy);
            })
            .catch((err) => {
                console.log("error: ", err)
            })
    };

    useEffect(() => {
        GetDetails();
    }, []);

    return (<>
        <div className="mx-8 h-auto  md:mx-20 mt-36 md:mt-28  lg:mt-16">
            <div className=" md:flex">
                <div className="hidden md:flex md:flex-col w-1/2">
                    <div className="flex md:mt-2">
                        <div className="md:text-lg lg:text-2xl xl:text-3xl lg:mt-1 font-semibold ">
                            Welcome to
                        </div>
                        <div className="ml-1  md:text-xl lg:text-3xl xl:text-4xl font-semibold text-[#0865B6]">
                            {companyName}
                        </div>
                    </div>
                    <div className="text-neutral-500 md:text-sm lg:text-lg">
                        {tagline}
                    </div>

                    <div className="mt-10 xl:mt-0 xl:pt-10 mb-10 2xl:p-20">
                        <img
                            className="w-0 md:w-full"
                            src={LogImg}
                            alt=""
                        />
                    </div>
                    <div className="grid justify-end">
                        <div className="mt-2 text-xs lg:text-base text-neutral-500">
                            Powered by HRI @MyBizmo
                        </div>
                    </div>
                </div>
                <div className="border-[3px] w-full md:p-20 p-[10] md:w-1/2  rounded-lg border-[#0865b6] md:ml-20 md:my-auto">
                    <div className="grid grid-cols-1 gap-y-4">
                        <div className="text-xl mb-4 md:mb-6 lg:mb-10 md:text-2xl lg:text-3xl whitespace-nowrap font-bold">
                            Forgot Password
                        </div>
                        <div className="mt-2 xl:mt-6">
                            <TextField
                                className="w-full"
                                label="Email"
                                placeholder="Enter Email ID"
                                id="outlined-size-small"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className=" text-sm sm:text-lg md:text-xl lg:text-3xl">
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={SendOtp}
                            >
                                Send
                            </Button>
                        </div>
                        <div>
                            <Button
                                fullWidth
                                variant="outlined"
                                style={{ borderColor: "#0865B6", color: "green" }}
                                onClick={() => navigate("/")}
                                className="text-sm"
                            >
                                Already have an account? Sign In
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default ForgotPass;
